import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiMail, FiCopy } from "react-icons/fi";

const ServiceList = [
    {
        title: 'Material',
        description: 'Cast or Steel Products come to our facility for enamel coating.'
    },
    {
        title: 'Paint Mixture',
        description: 'In our facility, these products are mixed with special additives and paint materials. It is distributed homogeneously with water. Almost any color enamel coating can be made with our special production materials.'
    },
    {
        title: 'Painting - Drying',
        description: 'Enamel coating paints of the products are made with the systems prepared with completely robotic new generation nanotechnology in our facility. Afterwards, the products are dried in a dust-free special environment. After this process is completed, the enamel coating process starts at 850 °C.'
    },
    {
        title: 'Packaging',
        description: 'After the oven process, the products that are dried again enter our special packaging band and enter the quality control and then carefully placed in the packages.'
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'History of Quality',
        description = 'The first foundation stones were established in 1958 by DÖKÜMİŞ ISI EMAYE SAN. and TİC. AS Our company has the most modern and advanced technology casting and enamel facility in Turkey. The locomotive company of the group of companies, DÖKÜMİŞ ISI EMAYE SAN. and TİC. AS It carries out its production in its factory on 20.500 m² closed and 47.650 m² open area. All products produced by the group of companies comply with EU norms.\n' +
            'DÖKÜMİŞ ISI EMAYE SAN., which has many quality certificates and certifies the quality in its production and management with total quality and ISO 9001:2008 management system. and TİC. AS It constantly controls and follows technological developments, innovations in design, and every stage in the sector, raising the bar for consumer-oriented, quality and design.',
        subtitle= 'excellence is our habit';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/service-details">
                                            <div className="service service__style--2 text-left">
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
