import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Due to its structure, the dishes are cooked as they are and they become more delicious.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Increases the Taste.. </span> </h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>It can be used for many years if the instructions for use are followed.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>It Lasts For Years..  </span> </h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Enamel has never lost its popularity over the years.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Always in Style.. </span> </h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Since enamel is non-porous, it does not harm or spoil food.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>It is healthy..</span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>





                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>


                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;