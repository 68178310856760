import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-8.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-9.jpg" alt="React Creative Agency" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Enamel On',
        title: 'Cast Iron',
        description: ''
    },
    {
        image: Portfolio_image2,
        category: 'Enamel On',
        title: 'Steel',
        description: ''
    },

]

class Portfolio extends Component{
    render(){
        let title = '',
        description = '';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="title">{title}</h2>
                                    <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="/">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="/">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title"><Link to="/">{value.title}</Link></h4>
                                                </div>
                                                <div className="portfolio_hover">
                                                    <p>{value.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="transparent_link" to="/"></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;