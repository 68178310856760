import React, { Component } from "react";

class AboutTwo extends Component{
    render(){
        let title = 'Hakkimizda',
        description = '';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--20 align-items-center">

                            <div className="col-lg-6 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">63 Years of Experience</span>
                                        <h4 className="title">The choice of companies from all over the world</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Latest Technology</h5>
                                                <p>We use the latest technologies</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Modern Facility</h5>
                                                <p>Modern facility equipped with technology</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Founded in 1958</h5>
                                                <p>1st class quality with 63 years of experience</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Green Factory</h5>
                                                <p>We care about nature</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;